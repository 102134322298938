import React from "react";
import Banner from "../home/Banner";
import Header from "../components/Header";
import "./../home/home.css";
import Garbhasanskar from "../home/Garbhasanskar";
import Whydoyouneed from "../home/Whydoyouneed";
import Footer from "../components/Footer";
import Thinkabout from "../home/Thinkabout";
import Confident from "../home/Confident";
import Sectionremedart from "../home/Sectionremedart";
import Packages from "../home/Packages";
import Serviceprovider from "../home/Serviceprovider";
import Sectionfour from "../home/Sectionfour";
import Successstories from "../home/Successstories";
import InteraktScript from "../../common/InteraktScript";
import Popup from "./Popuphome"

const Newhome = () => {
  const user = localStorage.getItem("userId");
  return (
    <>
      <div className="new-home">
        <Header userid={user} />
        <Banner />
        <Garbhasanskar user={user} />
        <Whydoyouneed />
        <Thinkabout />
        <Confident />
        <Sectionremedart />
        <Packages />
        <Serviceprovider />
        <Sectionfour />

        <Successstories />
        <Popup />
        <Footer />
        <InteraktScript />
      </div>
    </>
  );
};

export default Newhome;
