import React from "react";
//import Header from "../components/Header";
//import Footer from "../components/Footer";
import { useState } from "react";
import { useEffect } from "react";
import "../../App.css"; // Ensure this is the path to your CSS file
import api from "../../app-service/api/api";
import { useNavigate } from "react-router-dom";

const QuizForm = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [formdata, setFormdata] = useState({});
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Hello World", formdata);
    let payload = {
      name: formdata?.name,
      email: formdata?.email,
      age: formdata?.age,
      phone: formdata?.number,
      first_time_explorer: formdata?.Q1,
      superhero_challenges: formdata?.Q2,
      daily_vibe: formdata?.Q3,
      fan_of_leafy_greens: formdata?.Q4,
      dessert_connoisseur: formdata?.Q5,
      feeling_emotionally: formdata?.Q6,
      little_helpers: formdata?.Q7,
      pregnancy_care: formdata?.Q8,
      tech_buddy: formdata?.Q9,
      smooth_journey: formdata?.Q10,
      comments: formdata?.message,
    };
    api
      .enquiryform(payload)
      .then((res) => {
        setIsPopupVisible(true);
      })
      .catch((rej) => {
        console.log(rej);
      });
    //
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({
      ...formdata, // Preserve existing data
      [name]: value, // Update the specific field dynamically
    });
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const createInputGroup = (
    labelText,
    inputType,
    inputName,
    inputId,
    placeholder,
    onchange
  ) => (
    <div className="formbold-input-group">
      <label htmlFor={inputId} className="formbold-form-label">
        {labelText}
      </label>
      <input
        type={inputType}
        name={inputName}
        id={inputId}
        placeholder={placeholder}
        className="formbold-form-input"
        onChange={onchange}
        required
      />
    </div>
  );

  const createCheckboxGroup = (labelText, options, groupName, onchange) => (
    <div className="formbold-input-radio-wrapper">
      <p className="formbold-form-label">{labelText}</p>
      <div className="formbold-radio-flex">
        <div className="formbold-radio-group">
          <ul className="formbold-radio-label">
            {options.map((option) => (
              <li key={option.id}>
                <input
                  type="radio"
                  name={groupName}
                  value={option.value}
                  id={option.id}
                  onChange={onchange}
                />
                <label htmlFor={option.id} className="formbold-radio-label">
                  {option.text}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div className="formbold-main-wrapper" id="content">
      <div className="formbold-form-wrapper">
        <form onSubmit={handleSubmit}>
          <h1
            style={{
              paddingBottom: "20px",
              textAlign: "center",
              display: "block",
              color: "#da2f76",
              fontSize: "32px",
            }}
          >
            💖 "Your Personalized Roadmap of #MyPregnancy MustHave Awaits –
            Start Your Journey Today (It’s Free!)"
          </h1>
          <p
            style={{
              paddingBottom: "10px",
              fontSize: "18px",
              textAlign: "justify",
            }}
          >
            Congratulations on taking the first step toward a healthier and
            happier pregnancy journey! 💕 At Myprega, we’re here to make sure
            you feel supported every step of the way. By answering a few simple
            questions, we’ll craft a personalized weekly pregnancy care plan
            tailored just for you — and the best part? It’s completely free!
          </p>
          <p
            style={{
              paddingBottom: "30px",
              fontSize: "18px",
              textAlign: "justify",
            }}
          >
            It’s quick, it’s easy, and it’s designed with your unique needs in
            mind. Ready to start building your dream pregnancy care plan? Let’s
            dive in! ✨
          </p>

          {createInputGroup(
            "What should we call you on your personalized pregnancy care journey? 😊 (Type your name here)",
            "text",
            "name",
            "name",
            "Enter your name",
            handleInputChange
          )}
          {createInputGroup(
            "And lastly, your Email! Where should we send your tailored pregnancy care plan? 💌",
            "email",
            "email",
            "email",
            "Enter your email",
            handleInputChange
          )}
          {createInputGroup(
            "Hey there! Let’s start with a simple one—how young are you?",
            "number",
            "age",
            "age",
            "Enter your age",
            handleInputChange
          )}
          {createInputGroup(
            "Your contact number, please! We promise to only use it to share helpful updates and never to spam. What’s the best number to reach you?",
            "number",
            "number",
            "number",
            "Enter your number",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              Are you a first-time explorer of the pregnancy world, or have you
              already been on this magical journey before? Tell us!
            </span>,
            [
              {
                text: "Yes, I already been on this magical journey before",
                value: "Yes, I already been on this magical journey before",
                id: "q1a",
              },
              {
                text: "No, this will be my first pregnancy.",
                value: "No, this will be my first pregnancy.",
                id: "q1b",
              },
              {
                text: "I’ve had a miscarriage or pregnancy loss before.",
                value: "I’ve had a miscarriage or pregnancy loss before.",
                id: "q1c",
              },
            ],
            "Q1",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              Time to get real! Do you have any superhero challenges like
              diabetes, high BP, or thyroid that we should know about to tailor
              your journey?
            </span>,
            [
              {
                text: "High blood pressure",
                value: "High blood pressure",
                id: "q2a",
              },
              {
                text: "Diabetes",
                value: "Diabetes",
                id: "q2b",
              },
              {
                text: "Thyroid disorders (Hyperthyroidism or Hypothyroidism)",
                value: "Thyroid disorders (Hyperthyroidism or Hypothyroidism)",
                id: "q2c",
              },
              {
                text: "PCOS or PCOD",
                value: "PCOS or PCOD",
                id: "q2d",
              },
              {
                text: "None of the above ",
                value: "None of the above ",
                id: "q2e",
              },
              {
                text: "Having more than one  ",
                value: "Having more than one  ",
                id: "q2f",
              },
            ],
            "Q2",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              How would you describe your daily vibe? Are you always on the go,
              juggling a busy schedule, or do you enjoy a laid-back, relaxed
              routine? Give us a sneak peek into your lifestyle!
            </span>,
            [
              {
                text: "I am physically active and exercise regularly",
                value: "I am physically active and exercise regularly",
                id: "q3a",
              },
              {
                text: "I have a sedentary lifestyle (spend most time sitting or working).",
                value:
                  "I have a sedentary lifestyle (spend most time sitting or working).",
                id: "q3b",
              },
              {
                text: " Somewhere in between active and sedentary.",
                value: " Somewhere in between active and sedentary.",
                id: "q3c",
              },
            ],
            "Q3",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              What’s your daily plate looking like these days? Are you a fan of
              leafy greens, love indulging in spicy treats, or prefer quick
              bites on the go? Share your food story with us—we’d love to know
              what fuels you!
            </span>,
            [
              {
                text: "Vegetarian",
                value: "Vegetarian",
                id: "q4a",
              },
              {
                text: "Non-vegetarian",
                value: "Non-vegetarian",
                id: "q4b",
              },
              {
                text: "Eggetarian",
                value: "Eggetarian",
                id: "q4c",
              },
              {
                text: "Vegan",
                value: "Vegan",
                id: "q4d",
              },
              {
                text: "Other ",
                value: "Other ",
                id: "q4e",
              },
              ,
            ],
            "Q4",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              Are you all about greens, a dessert connoisseur, or following a
              specific diet plan? Do you have any known food allergies or
              dietary restrictions? Let us know your food vibes—we’ll keep them
              in mind! 🍎
            </span>,
            [
              {
                text: "Yes, I have food allergies or dietary restrictions",
                value: "Yes, I have food allergies or dietary restrictions",
                id: "q5a",
              },
              {
                text: "No, I don’t have food allergies or dietary restrictions",
                value:
                  "No, I don’t have food allergies or dietary restrictions",
                id: "q5b",
              },
            ],
            "Q5",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              How are you feeling emotionally these days? Are you riding the
              waves of joy, or do you feel like there’s a little storm cloud
              hovering? Share with us, so we can help make your journey
              brighter!
            </span>,
            [
              {
                text: "I feel emotionally well and supported.",
                value: "I feel emotionally well and supported.",
                id: "q6a",
              },
              {
                text: " I feel stressed occasionally but can manage it.",
                value: " I feel stressed occasionally but can manage it.",
                id: "q6b",
              },
              {
                text: "I feel overwhelmed or anxious frequently.",
                value: "I feel overwhelmed or anxious frequently.",
                id: "q6c",
              },
            ],
            "Q6",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              Let’s talk about your little helpers! Are you taking
              multi-vitamins, supplements, or planning to start them soon, or
              have you taken them in the past? Spill the tea!
            </span>,
            [
              {
                text: "Yes, I am taking prenatal vitamins.",
                value: "Yes, I am taking prenatal vitamins.",
                id: "q7a",
              },
              {
                text: "Yes, I am taking other multivitamins or supplements (e.g., iron, calcium).",
                value:
                  "Yes, I am taking other multivitamins or supplements (e.g., iron, calcium).",
                id: "q7b",
              },
              {
                text: "No, I am not currently taking any supplements",
                value: "No, I am not currently taking any supplements",
                id: "q7c",
              },
              {
                text: "Not applicable (I’m planning for future pregnancy).",
                value: "Not applicable (I’m planning for future pregnancy).",
                id: "q7d",
              },
            ],
            "Q7",
            handleInputChange
          )}
          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              What’s on your mind when it comes to pregnancy care? Are there any
              biggest question or worries that keep popping up? We’re here to
              understand and support you every step of the way!
            </span>,
            [
              {
                text: "Managing my health and the baby’s growth",
                value: "Managing my health and the baby’s growth",
                id: "q8a",
              },
              {
                text: "Financial affordability of pregnancy care",
                value: "Financial affordability of pregnancy care",
                id: "q8b",
              },
              {
                text: "Scheduling consultations and tests",
                value: "Scheduling consultations and tests",
                id: "q8c",
              },
              {
                text: "Getting continuous guidance from a doctor",
                value: "Getting continuous guidance from a doctor",
                id: "q8d",
              },
              {
                text: "Preparing for delivery ",
                value: "Preparing for delivery ",
                id: "q8e",
              },
              ,
            ],
            "Q8",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              Do you have a tech buddy (like an app or fitness tracker), or are
              you more of a “pen and paper” person? Let us know your health or
              fitness progress tracking style!
            </span>,
            [
              {
                text: "I use a mobile app or fitness tracker (e.g., for steps, sleep, etc.).",
                value:
                  "I use a mobile app or fitness tracker (e.g., for steps, sleep, etc.).",
                id: "q9a",
              },
              {
                text: "I rely on regular check-ups with doctors.",
                value: "I rely on regular check-ups with doctors.",
                id: "q9b",
              },
              {
                text: "I track manually (e.g., writing down weight or symptoms).",
                value:
                  "I track manually (e.g., writing down weight or symptoms).",
                id: "q9c",
              },
              {
                text: "I don’t track my health regularly.",
                value: "I don’t track my health regularly.",
                id: "q9d",
              },
            ],
            "Q9",
            handleInputChange
          )}

          {createCheckboxGroup(
            <span
              style={{
                fontSize: window.innerWidth <= 768 ? "18px" : "18px", // 40px for mobile, 18px for larger screens
              }}
            >
              Has pregnancy been a smooth journey in your family, or have there
              been any bumps along the way? If you or your loved ones have faced
              any challenges, we’d love to know so we can guide you better!
            </span>,
            [
              {
                text: "🌟 Yes, there have been complications.",
                value: "🌟 Yes, there have been complications.",
                id: "q10a",
              },
              {
                text: "🌸 No, it has been smooth sailing",
                value: "🌸 No, it has been smooth sailing",
                id: "q10b",
              },
              {
                text: "🤔 I'm not sure / Don't know ",
                value: "🤔 I'm not sure / Don't know.",
                id: "q10c",
              },
            ],
            "Q10",
            handleInputChange
          )}

          {/* Add additional checkbox groups here as needed */}

          <div className="formbold-input-group">
            <label htmlFor="message" className="formbold-form-label">
              Any comments or suggestions
            </label>
            <textarea
              rows="6"
              name="message"
              id="message"
              placeholder="Type here..."
              className="formbold-form-input"
              onChange={handleInputChange}
            ></textarea>
          </div>

          <button
            type="submit"
            className="formbold-btn"
            id="submitButton"
            style={{ backgroundColor: "#6a64f1" }}
          >
            Submit
          </button>
        </form>

        {isPopupVisible && (
          <div
            className="popup"
            id="popup"
            style={{
              display: "block",
              maxHeight: "90vh", // Set a max height for the popup
              overflowY: "auto", // Enable vertical scrolling
              padding: "20px", // Add some padding for better spacing
              border: "1px solid #ccc", // Optional: Add a border for better visibility
              borderRadius: "8px", // Optional: Rounded corners
              backgroundColor: "#fff", // Optional: Set background color
            }}
          >
            <img src="tick.png" alt="Success" />
            <h2 style={{ fontSize: "", fontWeight: "bold" }}>
              🎉 Thank you for sharing your details with us! 🎉 Your
              Personalized Pregnancy Care Plan is On Its Way!
            </h2>
            <p
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              Your journey toward a healthier, happier pregnancy starts now! 🌟
              Our team of experts is already working behind the scenes to craft
              your personalized #MyPregnancyMustHave roadmap.
            </p>
            <p style={{ fontSize: "20px", paddingBottom: "10px" }}>
              We’re putting extra care and thought into tailoring a weekly
              pregnancy care plan just for you. 🍼💖 You’ll receive it in your
              email inbox within the next 24 hours—stay tuned!
            </p>
            <p style={{ fontSize: "20px", paddingBottom: "10px" }}>
              At MyPrega, we’re revolutionizing pregnancy care by providing an
              all-in-one, technology-driven solution to guide you from day one
              of your pregnancy until delivery. With personalized care plans,
              standardized pricing, and daily health tracking, we bring
              convenience, affordability, and transparency to your pregnancy
              journey—all from the comfort of your home. Unlike traditional
              maternity care providers, MyPrega ensures daily engagement with
              doctors, round-the-clock emergency support, and a seamless
              experience through our easy-to-use platform.
            </p>
            <p
              style={{
                fontSize: "22px",
                paddingBottom: "10px",
                fontWeight: "bold",
              }}
            >
              ✨ Ready to explore more?
              <ul>
                <li style={{ fontSize: "20px" }}>
                  Schedule a demo call with us by clicking on the ‘Claim Your
                  Free Walkthrough’ button.
                </li>
                <li style={{ fontSize: "20px", paddingBottom: "15px" }}>
                  Or experience our free trial package by simply signing up on
                  our platform.
                </li>
              </ul>
              We can’t wait to be part of your pregnancy journey! 💖
            </p>
            <button
              type="button"
              onClick={closePopup}
              style={{ fontSize: "20px" }}
            >
              OK
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizForm;
