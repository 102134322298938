import React, { useState, useEffect } from "react";

const PopupHome = () => {
  const [isPopupHomeVisible, setPopupHomeVisible] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    console.log("paramhans    .........", userId);

    if (!userId) {
      const timer = setTimeout(() => {
        setPopupHomeVisible(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, []);

  const closePopupHome = () => {
    setPopupHomeVisible(false);
  };

  return (
    <>
      {isPopupHomeVisible && (
        <div
          className="popuphome-overlay"
          onClick={closePopupHome}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="popuphome"
            style={{
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "16px",
              padding: "30px",
              maxWidth: "500px",
              textAlign: "center",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Close button */}
            <button
              onClick={closePopupHome}
              style={{
                position: "absolute",
                top: "5px",
                right: "15px",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "38px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#333",
              }}
              aria-label="Close popup"
            >
              &times;
            </button>

            <h2
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#e91e63",
                marginBottom: "15px",
              }}
            >
              🎉 Get Your Free Personalized Pregnancy Journey Planner! 🎉
            </h2>
            <p
              style={{ fontSize: "16px", color: "#333", marginBottom: "10px" }}
            >
              Wondering what your perfect pregnancy care should look like?
              Answer a few quick questions, and our experts will create a
              detailed, personalized pregnancy journey planner just for you—for
              FREE! 💖
            </p>
            <p
              style={{ fontSize: "16px", color: "#333", marginBottom: "15px" }}
            >
              Your unique pregnancy journey planner will include everything from
              health tips, scans, and consultations to mental wellness and
              nutrition, tailored to YOUR needs
            </p>
            <p
              style={{
                fontSize: "18px",
                color: "#555",
                marginBottom: "15px",
                fontWeight: "bolder",
              }}
            >
              ✨Start now and take the first step toward a smooth, worry-free
              pregnancy journey!
            </p>
            <button
              onClick={() => {
                window.location.href = "/form"; // Direct navigation
              }}
              style={{
                backgroundColor: "#e91e63",
                color: "#fff",
                border: "none",
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              Get My Free Planner!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupHome;
